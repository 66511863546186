import { CrmFilterOptions, CrmTableOptions, ISelectItem } from '@/types/type';
import { ref } from 'vue';
/** 1客户 2客户池 3客户回收站 */
type ClientType = 1 | 2 | 3;
export default () => {
  const province = [
    '北京市',
    '天津市',
    '河北省',
    '山西省',
    '内蒙古自治区',
    '辽宁省',
    '吉林省',
    '黑龙江省',
    '上海市',
    '江苏省',
    '浙江省',
    '安徽省',
    '福建省',
    '江西省',
    '山东省',
    '河南省',
    '湖北省',
    '湖南省',
    '广东省',
    '广西壮族自治区',
    '海南省',
    '重庆市',
    '四川省',
    '贵州省',
    '云南省',
    '西藏自治区',
    '陕西省',
    '甘肃省',
    '青海省',
    '宁夏回族自治区',
    '新疆维吾尔自治区',
    '台湾省',
    '香港特别行政区',
    '澳门特别行政区',
  ];
  const getProvince = (): ISelectItem[] => {
    const provinces: ISelectItem[] = [];
    province.forEach((v) => {
      provinces.push({ label: v, value: v });
    });
    return provinces as ISelectItem[];
  };
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'customerName',
      value: '',
      placeholder: '请输入客户名称',
    },
    {
      type: 'input',
      prop: 'customerNo',
      value: '',
      placeholder: '请输入客户编号',
    },

    {
      type: 'multiSelect',
      prop: 'ownership',
      options: 'customer_ownership',
      value: [],
      placeholder: '请选择公司性质',
    },
    {
      type: 'signSelect',
      prop: 'addressProvince',
      value: '',
      options: getProvince(),
      width: 180,
      placeholder: '请选择地区',
    },
    {
      type: 'multiSelect',
      prop: 'annualRevenue',
      value: [],
      options: 'customer_revenue',
      width: 180,
      placeholder: '请选择年营业额',
    },
    {
      type: 'multiSelect',
      prop: 'employeeNumber',
      value: [],
      options: 'customer_number',
      width: 180,
      placeholder: '请选择员工数',
    },
    {
      type: 'multiSelect',
      prop: 'rating',
      value: [],
      options: 'customer_rating',
      width: 180,
      placeholder: '请选择星级',
    },

    {
      type: 'input',
      prop: 'creator',
      value: '',
      placeholder: '请输入创建人',
    },
    {
      type: 'time',
      prop: ['startTime', 'endTime'],
      value: [],
      placeholder: ['创建开始时间', '创建结束时间'],
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '客户名称',
      prop: 'customerName',
      width: 238,
    },
    {
      label: '客户编号',
      prop: 'customerNo',
      minWidth: 110,
    },
    {
      label: '公司性质',
      prop: 'ownershipName',
      minWidth: 110,
    },
    {
      label: '年营业额',
      prop: 'annualRevenueName',
      minWidth: 110,
    },
    {
      label: '员工数',
      prop: 'employeeNumberName',
      minWidth: 90,
    },
    {
      label: '星级',
      prop: 'ratingName',
      minWidth: 90,
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 120,
    },

    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 170,
    },
  ];
  return { filterOptions, columnList, tableRef };
};

//
